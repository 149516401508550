import React, { useState } from 'react';
import Typography from '@ohif/ui/src/components/Typography';
import { Button } from '@ohif/ui-next';


const SpineLabeling = ({ onClose }: { onClose: (label: string | null) => void }) => {
  const [openHistory, setOpenHistory] = useState(true);
  const [selectedLabel, setSelectedLabel] = useState<string | null>('C1');
  const [selectedOrder, setSelectedOrder] = useState<'ascend' | 'descend'>('ascend');
  const [selectedOrderT13, setSelectedOrderT13] = useState<'enableT13' | 'disableT13'>('enableT13');
  const [selectedOrderL6, setSelectedOrderL6] = useState<'enableL6' | 'disableL6'>('disableL6');
  const [selectedOrderSPM, setSelectedOrderSPM] = useState<'enableSPM' | 'disableSPM'>(
    'disableSPM'
  );
  const [isInclude, setIsinclude] = useState(false);
  const cervicalLabels = ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7'];
  const [thoracicLabels, setThoracicLabels] = useState([
    'T1',
    'T2',
    'T3',
    'T4',
    'T5',
    'T6',
    'T7',
    'T8',
    'T9',
    'T10',
    'T11',
    'T12',
    'T13',
  ]);
  const [lumbarLabels, setLumbarLabels] = useState(['L1', 'L2', 'L3', 'L4', 'L5']);
  const sacralLabels = ['S1', 'S2', 'S3', 'S4', 'S5'];
  const [includedLabels, setIncludedLabels] = useState([
    'C1',
    'C2',
    'C3',
    'C4',
    'T1',
    'T2',
    'T13',
    'L1',
    'L2',
    'S1',
    'S2',
  ]);
  const handleClosePopup = () => {
    setOpenHistory(false);
    onClose(selectedLabel);
  };

  const handleLabelClick = (label: string) => {
    if (isInclude) {
      if (includedLabels.includes(label)) {
        setIncludedLabels(prev => {
          const updatedLabels = prev.filter(item => item !== label);
          if (selectedLabel === label) {
            setSelectedLabel('');
          }
          console.log('Excluded label:', label);
          console.log('Updated included labels:', updatedLabels);
          return updatedLabels;
        });
      } else {
        setIncludedLabels(prev => {
          const updatedLabels = [...prev, label];
          console.log('Included label:', label);
          console.log('Updated included labels:', updatedLabels);
          return updatedLabels;
        });
      }
    } else {
      if (selectedLabel === label) {
        setSelectedLabel('');
      } else if (includedLabels.includes(label)) {
        setSelectedLabel(label);
      }
    }
  };

  const handleOrderChange = (order: 'ascend' | 'descend') => {
    setSelectedOrder(order);
  };
  const handleT13Status = (status: 'enableT13' | 'disableT13') => {
    setSelectedOrderT13(status);
    if (status === 'enableT13') {
      if (!thoracicLabels.includes('T13')) {
        setThoracicLabels(prev => [...prev, 'T13']);
      }
    } else {
      setThoracicLabels(prev => prev.filter(label => label !== 'T13'));
    }
  };

  const handleL6Status = (status: 'enableL6' | 'disableL6') => {
    setSelectedOrderL6(status);
    if (status === 'enableL6') {
      if (!lumbarLabels.includes('L6')) {
        setLumbarLabels(prev => [...prev, 'L6']);
      }
    } else {
      setLumbarLabels(prev => prev.filter(label => label !== 'L6'));
    }
  };

  const handleSPMStatus = (order: 'enableSPM' | 'disableSPM') => {
    setSelectedOrderSPM(order);
  };
  const handleIncludeExclude = () => {
    setIsinclude(prevState => !prevState);
  };

  return (

      <div className="maindiv  overflow-y-auto m-2 ">
        <div className="flex justify-around p-6">
          <div>
            <Typography
              variant="body"
              className="mb-4 text-lg font-medium"
            >
              Select the label to start from:
            </Typography>
          </div>
          <div className={`${isInclude ? `border-2 border-red-600` : ``} rounded`}>
            {/* Label Buttons */}
            <div className="flex">
              <div className="flex flex-col">
                {cervicalLabels.map(label => (
                  <button
                    key={label}
                    className={`m-2 rounded py-1 px-4 font-medium transition ${
                      selectedLabel === label
                        ? 'bg-red-600 text-white'
                        : includedLabels.includes(label)
                          ? 'bg-gray-600 text-white'
                          : `bg-gray-800 ${isInclude ? 'hover:bg-gray-600' : ''} text-white`
                    }`}
                    onClick={() => handleLabelClick(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
              <div className="flex flex-col">
                {thoracicLabels.map(label => (
                  <button
                    key={label}
                    className={`m-2 rounded py-1 px-4 font-medium transition ${
                      selectedLabel === label
                        ? 'bg-red-600 text-white'
                        : includedLabels.includes(label)
                          ? 'bg-gray-600 text-white'
                          : `bg-gray-800 ${isInclude ? 'hover:bg-gray-600' : ''} text-white`
                    }`}
                    onClick={() => handleLabelClick(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
              <div className="flex flex-col">
                {lumbarLabels.map(label => (
                  <button
                    key={label}
                    className={`m-2 rounded py-1 px-4 font-medium transition ${
                      selectedLabel === label
                        ? 'bg-red-600 text-white'
                        : includedLabels.includes(label)
                          ? 'bg-gray-600 text-white'
                          : `bg-gray-800 ${isInclude ? 'hover:bg-gray-600' : ''} text-white`
                    }`}
                    onClick={() => handleLabelClick(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
              <div className="flex flex-col">
                {sacralLabels.map(label => (
                  <button
                    key={label}
                    className={`m-2 rounded py-1 px-4 font-medium transition ${
                      selectedLabel === label
                        ? 'bg-red-600 text-white'
                        : includedLabels.includes(label)
                          ? 'bg-gray-600 text-white'
                          : `bg-gray-800 ${isInclude ? 'hover:bg-gray-600' : ''} text-white`
                    }`}
                    onClick={() => handleLabelClick(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Split Button for Ascend / Descend */}
        <div className="flex justify-around p-6">
          <div className="flex items-end">
            <Typography
              variant="body"
              className="mb-4 text-lg font-medium"
            >
              Toggle Label Order:
            </Typography>
          </div>
          <div className="flex">
            {/* Split Button */}
            <div className="relative inline-block">
              <button
                className={`${
                  selectedOrder === 'ascend'
                    ? 'bg-red-600 text-white'
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                } py-2 px-6 transition`}
                onClick={() => handleOrderChange('ascend')}
              >
                Ascend
              </button>
              <button
                className={`${
                  selectedOrder === 'descend'
                    ? 'bg-red-600 text-white'
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                } py-2 px-6 transition`}
                onClick={() => handleOrderChange('descend')}
              >
                Descend
              </button>
            </div>
          </div>
        </div>

        {/*Include/Exclude Labels*/}
        <div className="flex justify-evenly gap-x-40 p-6">
          <div className="flex items-end">
            <Typography
              variant="body"
              className="mb-4 text-lg font-medium"
            >
              Mark the Labels to be Excluded:
            </Typography>
          </div>
          <div className="flex">
            <div>
              <button
                onClick={handleIncludeExclude}
                className={`${isInclude ? `bg-red-600` : `bg-gray-700`} py-2 px-6 text-white`}
              >
                {!isInclude ? 'Exclude Labels' : 'Save Labels'}
              </button>
            </div>
          </div>
        </div>

        <div className="ml-32 flex">
          <div className="flex items-end">
            <Typography
              variant="body"
              className="mb-4 text-lg font-medium"
            >
              Pathalogical case:
            </Typography>
          </div>
        </div>

        <div className="flex justify-around p-6">
          <div className="flex items-end">
            <Typography
              variant="body"
              className="mb-4 text-lg font-medium"
            >
              T13:
            </Typography>
          </div>
          <div className="flex">
            {/* Split Button */}
            <div className="relative inline-block">
              <button
                className={`${
                  selectedOrderT13 === 'enableT13'
                    ? 'bg-red-600 text-white'
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                } py-2 px-6 transition`}
                onClick={() => handleT13Status('enableT13')}
              >
                Enable
              </button>
              <button
                className={`${
                  selectedOrderT13 === 'disableT13'
                    ? 'bg-red-600 text-white'
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                } py-2 px-6 transition`}
                onClick={() => handleT13Status('disableT13')}
              >
                Disable
              </button>
            </div>
          </div>
        </div>

        <div className="flex justify-around p-6">
          <div className="flex items-end">
            <Typography
              variant="body"
              className="mb-4 text-lg font-medium"
            >
              L6:
            </Typography>
          </div>
          <div className="flex">
            {/* Split Button */}
            <div className="relative inline-block">
              <button
                className={`${
                  selectedOrderL6 === 'enableL6'
                    ? 'bg-red-600 text-white'
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                } py-2 px-6 transition`}
                onClick={() => handleL6Status('enableL6')}
              >
                Enable
              </button>
              <button
                className={`${
                  selectedOrderL6 === 'disableL6'
                    ? 'bg-red-600 text-white'
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                } py-2 px-6 transition`}
                onClick={() => handleL6Status('disableL6')}
              >
                Disable
              </button>
            </div>
          </div>
        </div>

        <div className="flex justify-evenly p-6">
          <div className="flex items-end">
            <Typography
              variant="body"
              className="mb-4 text-lg font-medium"
            >
              Spine balance measurement:
            </Typography>
          </div>
          <div className="flex">
            {/* Split Button */}
            <div className="relative inline-block">
              <button
                className={`${
                  selectedOrderSPM === 'enableSPM'
                    ? 'bg-red-600 text-white'
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                } py-2 px-6 transition`}
                onClick={() => handleSPMStatus('enableSPM')}
              >
                Enable
              </button>
              <button
                className={`${
                  selectedOrderSPM === 'disableSPM'
                    ? 'bg-red-600 text-white'
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                } py-2 px-6 transition`}
                onClick={() => handleSPMStatus('disableSPM')}
              >
                Disable
              </button>
            </div>
          </div>
        </div>
        {/* Action Buttons */}
        <div className="p-4 flex justify-end gap-4">
          <Button
            variant="default"
            size="lg"  // Increased size
            disabled={!selectedLabel || isInclude}
            onClick={() => {
              if (selectedLabel) {
                setOpenHistory(false);
                onClose(selectedLabel);
              } else {
                alert('Please select a label!');
              }
            }}
          >
            Start Labeling
          </Button>

          <Button
            variant="destructive"
            size="lg"  // Increased size
            onClick={handleClosePopup}
          >
            Close
          </Button>
        </div>





    </div>
  );
};

export default SpineLabeling;
